import { render, staticRenderFns } from "./DetallesProductos.vue?vue&type=template&id=61ff9efc&scoped=true"
import script from "./DetallesProductos.vue?vue&type=script&lang=ts"
export * from "./DetallesProductos.vue?vue&type=script&lang=ts"
import style0 from "./DetallesProductos.vue?vue&type=style&index=0&id=61ff9efc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ff9efc",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QImg,QToolbar,QBtn,QDrawer,QScrollArea,QList,QItem,QItemSection,QItemLabel,QCard,QCardSection,QIcon});qInstall(component, 'directives', {ClosePopup,Ripple});
