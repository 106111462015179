
import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import Notify from 'quasar/src/plugins/Notify.js';;
import axios from "axios";

@Component({})
export default class TipicaChilena extends Vue {
  private win = window;
  private base = window.location;
  private left = false;
  private instagram: any = "";

  private nombreWeb: any = "";
  private dataProducto: any = "";

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoProducto();
    }
  }

  private getInfoProducto() {
    axios
      .get("productos/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        console.log(res);
        this.dataProducto = res.data;
        for (let i = 0; i < this.dataProducto.length; i++) {
          if (this.dataProducto[i].imagen != "") {
            this.dataProducto[i].imagen =
              " url_media" +
              this.dataProducto[i].imagen;
          }
          // if (this.dataProducto[i].instagram != "") {
          //   let nombreInsta = this.dataProducto[i].instagram;
          //   this.instagram = "@" + nombreInsta.slice(26, 37);
          // }
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private irGoogleMaps(data: any) {
    window.open("https://www.google.com/maps/search/?api=1&query=" + data);
  }

  private llamar(data: any) {
    const numero = data;
    window.open("tel:" + numero);
  }

  private copiarClipboard(texto: any) {
    copyToClipboard(texto)
      .then(() => {
        Notify.create({
          color: "positive",
          message: "Se ha copiado el Número de Teléfono.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      })
      .catch(() => {
        Notify.create({
          color: "negative",
          message: "Ha ocurrido un problema. Por favor, inténtelo de nuevo.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        return;
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
